<template>
  <div class="account">
    <div
      class="account__sidebar"
      :class="{ 'account__sidebar--hide-mobile': isRouteAccount }"
    >
      <h1 class="account__title">My Account</h1>

      <div class="account__navigation navigation">
        <template v-for="(item, index) of navigation">
          <router-link :key="index" :to="item.path" class="navigation__item">
            <img :src="item.iconPath" alt="" class="navigation__icon" />
            <img
              :src="item.iconPathActive"
              alt=""
              class="navigation__icon navigation__icon--active"
            />

            <div class="navigation__title">
              {{ item.title }}
            </div>

            <img
              :src="require('@/assets/icons/account/right-arrow-black.svg')"
              alt=""
              class="navigation__arrow"
            />
            <img
              :src="require('@/assets/icons/account/right-arrow-white.svg')"
              alt=""
              class="navigation__arrow navigation__arrow--active"
            />
          </router-link>
        </template>

        <div
          v-if="isMobile"
          class="navigation__item navigation__item--bottom"
          @click="logout"
        >
          <div class="navigation__title">
            Logout
          </div>

          <img
            :src="require('@/assets/icons/account/logout-black.svg')"
            alt=""
            class="navigation__arrow"
          />
          <img
            :src="require('@/assets/icons/account/logout-white.svg')"
            alt=""
            class="navigation__arrow navigation__arrow--active"
          />
        </div>
      </div>
    </div>

    <div class="account__content">
      <router-view />
    </div>
  </div>
</template>

<script>
  import device from '@/service/device-service.js';
  import Auth from '@/service/authService';
  import WishlistService from '@/store/wishlist';

  export default {
    name: 'AccountLayout',

    data() {
      return {
        navigation: [
          {
            path: '/account/profile',
            title: 'Profile',
            iconPath: require('@/assets/icons/account/profile-black.svg'),
            iconPathActive: require('@/assets/icons/account/profile-white.svg'),
          },
          {
            path: '/account/orders',
            title: 'My Orders',
            iconPath: require('@/assets/icons/account/orders-black.svg'),
            iconPathActive: require('@/assets/icons/account/orders-white.svg'),
          },
          {
            path: '/account/favorites',
            title: 'Wishlist',
            iconPath: require('@/assets/icons/account/favorites-black.svg'),
            iconPathActive: require('@/assets/icons/account/favorites-white.svg'),
          },

          {
            path: '/account/message-center',
            title: 'Messaging Center',
            iconPath: require('@/assets/icons/account/message-center-black.svg'),
            iconPathActive: require('@/assets/icons/account/message-center-white.svg'),
          },
          {
            path: '/account/notifications',
            title: 'Notifications',
            iconPath: require('@/assets/icons/account/notification-black.svg'),
            iconPathActive: require('@/assets/icons/account/notification-white.svg'),
          },
          // { path: '/account/review', title: 'My Reviews', iconPath: require('@/assets/icons/account/orders-black.svg'), iconPathActive: require('@/assets/icons/account/orders-white.svg')  },
          // { path: '/account/questions', title: 'My Questions', iconPath: require('@/assets/icons/account/favorites-black.svg'), iconPathActive: require('@/assets/icons/account/favorites-white.svg')  },
        ],
      };
    },
    computed: {
      isRouteAccount() {
        return this.$route.name !== 'account';
      },
      isMobile() {
        return device.isAnyMobile() || device.isMobileSize();
      },
    },
    methods: {
      logout() {
        this.$bus.$emit('openSignOutDialog', {
          isAcceptHidden: false,
          callFromFooter: false,
        });
        // const CONFIRM_CONFIG = {
        //   centered: true,
        //   size: 'confirm',
        //   cancelTitle: 'No',
        //   okTitle: 'Yes',
        // };
        //
        // this
        //   .$bvModal
        //   .msgBoxConfirm('Do you really want to sign out of Shoprz', CONFIRM_CONFIG)
        //   .then(async (confirm) => {
        //     if (confirm) {
        //       await Auth.logOut();
        //       WishlistService.clear();
        //       this.$bus.$emit('logout');
        //
        //       if (this.$route.path.includes('wishlist')) {
        //         this.$router.push('/')
        //       }
        //     }
        //   });
      },
    },
  };
</script>

<style scoped lang="scss">
  .account {
    margin: 0 auto;
    padding: 20px 15px 0;
    display: flex;
    gap: 30px;
    max-width: 1450px;
    width: 100%;

    // WEB2-4083 important for mobile devices. Do not remove. or check browserstack.com
    @media (max-width: 800px) {
      height: calc(100vh - 210px) !important;
    }

    &__title {
      margin-bottom: 25px;
      font-weight: 500;
      font-size: 26px;
      line-height: 20px;
      color: #343434;
    }

    &__sidebar {
      width: 240px;
    }

    &__content {
      width: calc(100% - 270px);
    }

    @media (max-width: 1000px) {
      padding: 40px 20px 0;
      display: block;
      height: calc(100vh - 100px);
      overflow-y: auto;

      &__title {
        margin-bottom: 17px;
        font-size: 22px;
      }

      &__sidebar {
        padding-bottom: 30px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        &--hide-mobile {
          display: none;
        }
      }

      &__content {
        width: 100%;
      }
    }
  }

  .navigation {
    &__item {
      padding: 15px 20px;
      display: flex;
      align-items: center;
      border-radius: 5px;
      color: #343434;
      transition: 0.3s ease all;
      cursor: pointer;

      &--bottom {
        margin-top: auto;
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      &.router-link-active {
        background: #21a66e;
        color: #fff;

        .navigation__arrow {
          display: none;
        }
        .navigation__arrow--active {
          display: block;
        }
        .navigation__icon {
          display: none;
        }
        .navigation__icon--active {
          display: block;
        }
      }

      &:hover {
        background: #21a66e;
        color: #fff;

        .navigation__arrow {
          display: none;
        }
        .navigation__arrow--active {
          display: block;
        }
        .navigation__icon {
          display: none;
        }
        .navigation__icon--active {
          display: block;
        }
      }
    }

    &__icon {
      margin-right: 10px;

      &--active {
        display: none;
      }
    }

    &__title {
      margin-right: auto;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
    }

    &__arrow {
      &--active {
        display: none;
      }
    }

    @media (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      height: 100%;

      &__item {
        background: #f8f8fb;
      }

      &__icon {
        display: none !important;
      }
    }
  }
</style>