<template>
  <div class="account">
    <div class="account__content">
      <router-view/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OrderLayout',
  };
</script>


<style scoped lang="scss">
  .account {
    margin: 0 auto;
    padding: 17px 15px 30px 15px;
    display: flex;
    gap: 30px;
    max-width: 1450px;
    width: 100%;

    &__content {
      width: 100%;
    }
  }
</style>