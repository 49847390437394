<template>
  <component :is="layout" />
</template>

<script>
  import AccountLayout from './layouts/account.layout';
  import OrderLayout from './layouts/order.layout';

  export default {
    name: 'AccountView',

    components: {
      AccountLayout,
      OrderLayout,
    },
    computed: {
      layout() {
        // TODO: we should rename layouts here
        if (this.$route.meta.hideSideMenu) {
          return OrderLayout;
        }
        return AccountLayout;
      },
    },
    mounted() {
      this.$stats.send('page_opened', {
        page_type: 'account',
      });
    },
  };
</script>